import contentBenefits from "@/assets/json/content-benefits.json";
import startupBenefits from "@/assets/json/startup-benefits.json";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import availablePricesDev from "@/hooks/availablePrices.dev";
import availablePricesMonthlyDev from "@/hooks/availablePrices.monthly.dev";
import availablePricesMonthlyProd from "@/hooks/availablePrices.monthly.prod";
import { ArrowFatLineUp, Check, X, Warning } from "@phosphor-icons/react";
import availablePricesProd from "@/hooks/availablePrices.prod";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";
import LucidMascot from "@/assets/images/lucid-mascot.png";
import { purchasePopup, hidePurchasePopup } from "@/Atoms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAtom } from "jotai";
import useAuth from "@/hooks/auth";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import AppContext from "@/AppContext";
import Image from "next/legacy/image";
import allAccessIcon from "@/assets/images/all_access_icon.png";
import { ScrollArea } from "@/components/ui/scroll-area";
import { getStripePrice } from "@/services/api/Stripe";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import Link from "next/link";
import { createAbandonedCart } from "@/services/api/User";
import { userAtom } from "@/Atoms";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { checkCoupon } from "@/services/api/User";
import { createStripeCheckout } from "@/services/api/Stripe";
import { createStartupByUser, updateToClaim } from "@/services/api/Startup";
import toast from "react-hot-toast";
import productsDev from "@/hooks/products.dev";
import productsProd from "@/hooks/products.prod";

const products =
process.env.ENV !== "production" ? productsDev : productsProd;

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);


const theme = "light";

const StartupCheckout = ({
  userLocation,
  currencyCode,
  priceData,
  priceIds,
  ip,
  isOpen,
  setIsOpen,
  createStartup,
  createStartupPayload,
  claimStartup,
  claimStartupPayload,
}) => {
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchasePopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);

  const router = useRouter();
  const { type } = router.query;
  const planType =
    openPurchasePopup === "startup" ? "Startup Registration" : "Content Access";
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [userState, setUserState] = useAtom(userAtom);
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponErrorProduct, setCouponErrorProduct] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [inputCoupon, setInputCoupon] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [loadingCheck, setloadingCheck] = useState(false);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const handleCheckCoupon = async (e) => {
    e.preventDefault();
    if (!inputCoupon) return;
    if (loadingCheck || coupon) return;
    setCouponError(false);
    setCouponErrorProduct(false);
    setCoupon(null);
    setCoupons(null);
    setloadingCheck(true);
    try {
      const res = await checkCoupon(inputCoupon);
      console.log(res);
      if (res.data.redeem_by && Date.now() > res.data.redeem_by * 1000) {
        setCouponError(true);
        setloadingCheck(false);
        return;
      }
      if (res.data.all && res.data.all.data) {
        setCoupons(res.data.all.data);
        const found = res.data.all.data.find((c) => c.id === res.data.id);
        if (found) {
          if (found.applies_to && found.applies_to.products) {
            if (
              found.applies_to.products.find(
                (product) => product === products[planType]
              )
            ) {
              setCoupon(res.data);
            } else {
              setCoupon(null);
              setCouponErrorProduct(true);
            }
          } else {
            setCoupon(res.data);
          }
        }
      } else {
        setCoupon(res.data);
      }
      setloadingCheck(false);
    } catch (error) {
      console.log(error);
      setCouponError(true);
      setloadingCheck(false);
      setCoupon(null);
      setCouponErrorProduct(false);
    }
  };

  const handleSubscription = async (event) => {
    if (!priceData) {
      return;
    }
    if (!user) {
      router.push("/auth/login?redirect_to=/pricing");
      return;
    }
    setIsOpen(false);
    setOpen(true);
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await createStripeCheckout({
      userEmail: user.email,
      priceId: priceData["Startup Registration"].id,
      type: "startup",
      coupon_id: coupon ? coupon.id : null,
      cancel_url: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
      // success_url: router.query?.redirect_to
      //   ? process.env.NEXT_PUBLIC_BASE_URL + router.query.redirect_to
      //   : null,
    });

    try {
      if (createStartup && createStartupPayload) {
        await createStartupByUser(createStartupPayload);
      }

      if (claimStartup && claimStartupPayload) {
        await updateToClaim(claimStartupPayload.startup_id, claimStartupPayload);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      }
      return
    }

    const session = response.data;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      alert(result.error.message);
    }
  };

  const onRemoveCoupon = () => {
    setInputCoupon("");
    setCoupon(null);
  };

  const onCancel = () => {
    setIsOpen(false);
    setInputCoupon("");
    setCoupon(null);
    setIsCoupon(false);
  };

  const currentPath = router.asPath;

  const handleCheckboxChange = () => {
    if (isCoupon) {
      setInputCoupon("");
      setCoupon(null);
    }
    setIsCoupon(!isCoupon);
  };

  const handleCouponChange = (e) => {
    setInputCoupon(e.target.value);
  };

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: user?.id,
      email: user?.email,
      action: "Click Startup Registration Trial",
      location: router.pathname,
      label: "Startup Registration",
    });
  };

  useEffect(() => {
    handleAbandonedCart();
  }, []);

  return (
    <AlertDialog open={isOpen} onClose={() => setIsOpen(false)}>
      <AlertDialogContent className='overflow-x-auto text-gray-800 bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='mb-4'>
            Start Your 7-Day Free Trial of the Startup Registration Plan
          </AlertDialogTitle>
          <div className='p-4 mt-2 rounded-lg bg-slate-100'>
            <div className='flex justify-between mb-2 font-semibold'>
              <p>Price</p>
              {priceData ? <p>
                {currencyCode !== "USD" ? currencyCode + " " : "$"}
                {(priceData
                  ? priceData["Startup Registration"].unit_amount / 100
                  : 0
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p> : <p className="animate-pulse">Loading...</p>}
            </div>
            <div className='pt-5 pb-5'>
              <label className='flex items-center space-x-2 cursor-pointer'>
                <Checkbox
                  onCheckedChange={handleCheckboxChange}
                  className={`${
                    theme === "light"
                      ? "border-background/50"
                      : "border-white/10"
                  }`}
                />
                <span className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                  I have a coupon code
                </span>
              </label>
              {isCoupon && (
                <div className='flex items-center justify-between pt-5'>
                  <p className='text-sm font-medium'>Coupon Code (Optional)</p>
                  <div>
                    <div className='flex gap-2'>
                      <Input
                        onChange={handleCouponChange}
                        className='bg-white/5 hover:bg-white/10'
                        disabled={coupon}
                        value={inputCoupon}
                        onBlur={() => setCouponError(false)}
                      />
                      <Button
                        type='button'
                        onClick={coupon ? onRemoveCoupon : handleCheckCoupon}
                        className={`${
                          coupon
                            ? "bg-red-500/20 hover:bg-red-500/30 text-red-400"
                            : `${
                                theme === "light"
                                  ? "bg-background/70 text-white hover:bg-background/50"
                                  : "bg-white text-background"
                              }`
                        }`}
                      >
                        {loadingCheck
                          ? "Checking..."
                          : coupon
                          ? "Remove"
                          : "Apply"}
                      </Button>
                    </div>
                    {coupon && (
                      <p className='flex items-center font-medium mt-1.5 text-xs text-green-500 gap-x-1.5'>
                        <Check weight='bold' /> Coupon Applied
                      </p>
                    )}
                    {couponErrorProduct && (
                      <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                        <Warning weight='bold' /> This coupon is not valid for
                        this subscription.
                      </p>
                    )}
                    {couponError && (
                      <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                        <Warning weight='bold' /> This Coupon does not exist or
                        has expired.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
            {coupon && (
              <div className=''>
                <div className='flex justify-between'>
                  <p>
                    Coupon <span className='text-sm'>({coupon.id})</span>
                  </p>
                  <p>
                    -{currencyCode !== "USD" ? currencyCode + " " : "$"}
                    {coupon.percent_off
                      ? Math.round(
                          (coupon.percent_off / 10000) *
                            priceData["Startup Registration"].unit_amount
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : (coupon.amount_off / 100).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </p>
                </div>
                <div className='flex justify-between mt-2 font-bold'>
                  <p>Total</p>
                  <p>
                    {currencyCode !== "USD" ? currencyCode + " " : "$"}
                    {coupon.percent_off
                      ? (
                          priceData["Startup Registration"].unit_amount / 100 -
                          Math.round(
                            (coupon.percent_off / 10000) *
                              priceData["Startup Registration"].unit_amount
                          )
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : (
                          priceData["Startup Registration"].unit_amount / 100 -
                          coupon.amount_off / 100
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/* <AlertDialogDescription>
          By clicking &quot;Proceed&quot;, you agree to
          proceed with the payment process and will be
          redirected to the secure payment page.
        </AlertDialogDescription> */}
        </AlertDialogHeader>
        <AlertDialogFooter className='flex sm:justify-between'>
          <AlertDialogCancel onClick={onCancel} className='hover:bg-slate-100 bg-white'>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={handleSubscription}
            disabled={!priceData}
            className='text-white bg-gray-800 hover:bg-gray-500'
          >
            {priceData ? 'Go to Payment' : 'Loading...'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default StartupCheckout;
