import contentBenefits from "@/assets/json/content-benefits.json";
import startupBenefits from "@/assets/json/startup-benefits.json";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import availablePricesDev from "@/hooks/availablePrices.dev";
import availablePricesMonthlyDev from "@/hooks/availablePrices.monthly.dev";
import availablePricesMonthlyProd from "@/hooks/availablePrices.monthly.prod";
import { ArrowFatLineUp, Check, X } from "@phosphor-icons/react";
import availablePricesProd from "@/hooks/availablePrices.prod";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";
import LucidMascot from "@/assets/images/lucid-mascot.png";
import { purchasePopup, hidePurchasePopup } from "@/Atoms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAtom } from "jotai";
import CardForm from "../stripe/CardForm";
import useAuth from "@/hooks/auth";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import AppContext from "@/AppContext";
import Image from "next/legacy/image";
import allAccessIcon from "@/assets/images/all_access_icon.png";
import { ScrollArea } from "../ui/scroll-area";
import { getStripePrice } from "@/services/api/Stripe";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import Link from "next/link";
import { createAbandonedCart } from "@/services/api/User";
import { userAtom } from "@/Atoms";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const steps = [
  { id: "Step 1", name: "Startup Information", href: "#", status: "current" },
  { id: "Step 2", name: "Payment Details", href: "#", status: "" },
];

const Purchase = () => {
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchasePopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);

  const router = useRouter();
  const { type } = router.query;
  const planType =
    openPurchasePopup === "startup" ? "Startup Registration" : "Content Access";
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [userState, setUserState] = useAtom(userAtom);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const handleUserIpAddress = async () => {
    let response;

    try {
      response = await axios.get(
        "https://api.bigdatacloud.net/data/ip-geolocation",
        {
          params: {
            key: "bdc_e7257c8213814ec68c8bf6708ad4431d",
          },
        }
      );
      console.log(response);
    } catch (error) {}

    const availablePrices =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesDev
        : availablePricesProd;

    const availablePricesMonthly =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesMonthlyDev
        : availablePricesMonthlyProd;

    let priceData = {};
    let priceDataMonthly = {};
    let currencyCode = "USD";
    let priceIds = availablePrices["USD"];
    let priceIdsMonthly = availablePricesMonthly["USD"];
    const codes = ["AED", "IDR", "JOD", "EGP", "SAR"];

    if (response && response.data?.country) {
      const currency = response.data.country.currency;
      if (currency && currency.code) {
        if (codes.includes(currency.code)) {
          currencyCode = currency.code;
          priceIds = availablePrices[currencyCode];
          priceIdsMonthly = availablePricesMonthly[currencyCode];
        }
      }
    }

    for (const pKey in priceIds) {
      const stripePriceDataResponse = await getStripePrice(priceIds[pKey]);
      const stripePriceData = stripePriceDataResponse.data;

      const stripePriceDataMonthlyResponse = await getStripePrice(
        priceIdsMonthly[pKey]
      );
      const stripePriceDataMonthly = stripePriceDataMonthlyResponse.data;

      priceData[pKey] = stripePriceData;
      priceDataMonthly[pKey] = stripePriceDataMonthly;
    }

    setState({
      userLocation: response?.data || null,
      currencyCode,
      priceData,
      priceDataMonthly,
      priceIds,
      priceIdsMonthly,
      ip: response?.data.ip,
    });
  };

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: userState?.id,
      email: userState?.email,
      action: "Click Upgrade Plan Button",
      location: router.pathname,
      label:
        openPurchasePopup === "startup"
          ? "Startup Registration"
          : "All-Access Pass",
    });
  };

  useEffect(() => {
    handleUserIpAddress();
    handleAbandonedCart();
  }, []);

  return (
    <Dialog open={!!openPurchasePopup} onOpenChange={setOpenPurchasePopup}>
      <DialogContent
        isHidePurchasePopup={isHidePurchasePopup}
        className={`max-w-6xl p-0 bg-white text-background ${
          isHidePurchasePopup ? "hidden" : ""
        }`}
        hideCloseButton
      >
        <ScrollArea className='max-h-[100dvh]'>
          <div className='flex flex-col lg:flex-row'>
            <div className='relative lg:flex-1 lg:m-2.5 lg:mr-0 overflow-hidden bg-gray-100 lg:rounded-md shadow-inner'>
              <button
                onClick={() => setOpenPurchasePopup(false)}
                type='button'
                className='absolute inline-flex md:hidden items-center justify-center rounded-full w-9 h-9 bg-white/[0.15] right-3 top-3'
              >
                <X className='text-xl text-white' />
              </button>
              <div className='p-8 pt-5 text-center text-white lg:pb-10 bg-gradient-to-tr from-orange-1 to-red-1'>
                <span className='inline-flex items-center justify-center mb-3 text-3xl rounded-full w-14 h-14 bg-white/20'>
                  <ArrowFatLineUp />
                </span>
                <p className='text-3xl font-semibold tracking-tight lg:text-4xl font-display'>
                  {openPurchasePopup === "startup"
                    ? "Start Your 7-Day Free Trial of the Startup Registration Plan Today"
                    : "Upgrade to All-Access Plan"}
                  !
                </p>
                <p className='mt-3 opacity-80'>
                  {openPurchasePopup === "startup"
                    ? "We offer a limited 7-day free trial to startups to create and publish your own startup profile, and start browsing other startups and investors on our database."
                    : "and enjoy all of our content without limitation"}
                </p>
              </div>
              <div className='justify-center hidden lg:flex lg:-mt-5'>
                <div className='flex items-center p-1 px-3 bg-white rounded-full shadow-lg gap-x-2'>
                  <div className='overflow-hidden rounded-full w-9 h-9'>
                    <div className='relative h-14 mx-auto aspect-[199/320]'>
                      <Image
                        src={LucidMascot.src}
                        alt=''
                        layout={"fill"}
                        objectFit={"cover"}
                      />
                    </div>
                    {/* <div className='relative w-9 h-9 aspect-square'>
                      <Image
                        src={allAccessIcon.src}
                        alt=''
                        layout='fill'
                        objectFit='cover'
                      />
                    </div> */}
                  </div>
                  <div>
                    <p className='text-xs font-medium leading-none'>
                      {openPurchasePopup === "startup"
                        ? "Startup Registration"
                        : "All-Access Pass"}
                    </p>
                  </div>
                </div>
              </div>
              <div className='grid gap-2 p-5 text-sm lg:grid-cols-2 lg:text-xs lg:p-8 text-background'>
                {(openPurchasePopup === "startup"
                  ? startupBenefits
                  : contentBenefits
                ).map((item, index) => (
                  <div
                    className='inline-flex items-start space-x-2.5'
                    key={index}
                  >
                    <span className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                      <Check weight='bold' className='text-xs' />
                    </span>
                    <span className='translate-y-px'>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='p-5 lg:p-7 lg:w-2/5'>
              {state ? (
                <Elements stripe={stripePromise} options={options}>
                  <CardForm
                    theme='light'
                    planType={
                      openPurchasePopup === "startup"
                        ? "Startup Registration"
                        : "Content Access"
                    }
                    refreshUserSubscription={refreshUserSubscription}
                    planSubscription={planSubscription}
                    {...{
                      userLocation: state?.userLocation,
                      priceData: state?.priceData,
                      priceDataMonthly: state?.priceDataMonthly,
                      priceIds: state?.priceIds,
                      priceIdsMonthly: state?.priceIdsMonthly,
                      currencyCode: state?.currencyCode,
                      ip: state?.ip,
                    }}
                  />
                </Elements>
              ) : (
                <div className='flex items-center justify-center'>
                  <p className='text-slate-500 animate-pulse'>
                    Loading Payment
                  </p>
                </div>
              )}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default Purchase;
