const availablePrices = {
  AED: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjZMqTGP5q",
    "Content Access": "price_1NzJ8rGlqmUCDtkjsuCyXOPC",
    "VC Registration": "price_1Paq6zGlqmUCDtkjvDeDcFd9",
  },
  IDR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjnb6hPieV",
    "Content Access": "price_1NzKaaGlqmUCDtkj06M2wlAe",
    "VC Registration": "price_1Paq6zGlqmUCDtkjTwvClA5l",
  },
  USD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkj3m5B89Ra",
    "Content Access": "price_1NzJ9iGlqmUCDtkj0yn0nP1D",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx",
  },
  JOD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjdjdsx9nN",
    "Content Access": "price_1NzJCVGlqmUCDtkjZdNe84MF",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx",
  },
  SAR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjwxJ45v4J",
    "Content Access": "price_1NzJAqGlqmUCDtkjlSqHoKCx",
    "VC Registration": "price_1Paq6zGlqmUCDtkjQo59LUYf",
  },
  EGP: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjfMlFm5JI",
    "Content Access": "price_1NzJBpGlqmUCDtkjObBXdLmI",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx",
  },
};

export default availablePrices;
