import { Dialog, DialogContent } from "@/components/ui/dialog";
import useAuth from "@/hooks/auth";
import { getStartupDetailSimple } from "@/services/api/Startup";
import checkEmailDomain from "@/utils/checkEmailDomain";
import { CircleNotch } from "@phosphor-icons/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import GoogleSignInButton from "../auth/GoogleSignInButton";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { useAtom } from "jotai";
import { loginPopup, registerPopup } from "@/Atoms";
import AppContext from "@/AppContext";
import ErrorMessage from "../ErrorMessage";
import {
  sendTokenNotUser,
  verifyTokenForUserRegister,
} from "@/services/api/Master";
import InputTokenForAccountRegister from "./InputTokenForAccountRegister";

const Login = () => {
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] =
    useState(false);
  const [emailConfirmLoading, setEmailConfirmLoading] = useState(false);
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [startup, setStartup] = useState(null);
  const [errors, setErrors] = useState([]);
  const [confirmToken, setConfirmToken] = useState("");
  const [sendEmailVerificationLoading, setSendEmailVerificationLoading] = useState(false)


  const { user, login, isLoading, loginError } = useAuth({
    middleware: "guest",
    redirectIfAuthenticated: false,
  });
  
  const { query } = router;
  const type = query.purpose;
  const startup_id = query.startup_id;

  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;

  const getStartup = async (id) => {
    const response = await getStartupDetailSimple(id);
    if (response.data) {
      setStartup(response.data);
    }
  };

  const emailInputRef = useRef()

  const onVerifyTokenUserRegister = async (e) => {
    e.preventDefault();
    if (!confirmToken) toast.error("Token is required!");
    setEmailVerificationLoading(true);
    setEmailConfirmLoading(true);
    try {
      const response = await verifyTokenForUserRegister({
        token: confirmToken,
        email,
      });
      try {
        await login({ email, password, setErrors, notRedirect: true });
        location.reload();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      toast.error("Code is invalid or expired.");
    }
    setEmailVerificationLoading(false);
    setEmailConfirmLoading(false);
  };

  const onSendToken = async (e) => {
    if (!email) {
      return toast.error("Email is required!");
    }

    if (startup && !checkEmailDomain(email, startup.website)) {
      return toast.error("The email must match the startup website!");
    }

    setEmailVerificationLoading(true);
    try {
      const response = await sendTokenNotUser({ email });
      toast.success(
        `Verification token just sent to ${email}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setEmailVerificationLoading(false);
  };

  const onResendToken = async (e) => {
    if (!email) {
      return toast.error("Email is required!");
    }

    if (startup && !checkEmailDomain(email, startup.website)) {
      return toast.error("The email must match the startup website!");
    }

    setSendEmailVerificationLoading(true);
    try {
      const response = await sendTokenNotUser({ email });
      toast.success(
        `Verification token just sent to ${email}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setSendEmailVerificationLoading(false);
  };

  useEffect(() => {
    if (type === "register" || type === "claim") {
      if (startup_id) {
        getStartup(startup_id);
      } else {
        const startupName = query.startup_name;
        const startupWebsite = query.startup_website;
        if (startupName && startupWebsite) {
          setStartup({
            name: startupName,
            website: startupWebsite,
          });
        }
      }
    }
    emailInputRef.current?.focus()
  }, []);

  // useEffect(() => {
  //   if (!openLoginPopup) {
  //     localStorage.removeItem('nextStep')
  //   }
  //   console.log(openLoginPopup)
  // }, [openLoginPopup])

  function switchPopup() {
    setOpenLoginPopup(false);
    setOpenRegisterPopup(true);
  }

  const submit = async function (evt) {
    evt.preventDefault();
    if (startup && !checkEmailDomain(email, startup.website)) {
      return toast.error("The email must match the startup website!");
    }
    await login({
      email,
      password,
      setErrors,
      startup,
      purpose: type,
    });
    refreshUserSubscription();
  };

  return (
    <Dialog
      open={isSendingVerifyEmail ? true : openLoginPopup}
      onOpenChange={setOpenLoginPopup}
    >
      <DialogContent
        className='p-8 pb-5 bg-white text-background'
        hideCloseButton={true}
      >
        {isSendingVerifyEmail ? (
          <InputTokenForAccountRegister
            {...{
              isVisible: isSendingVerifyEmail ? true : openLoginPopup,
              onVerifyTokenUserRegister,
              emailVerificationLoading,
              confirmToken,
              setConfirmToken,
              isLoading,
              sendEmailVerificationLoading,
              onSendTokenForAccountRegistration: onResendToken
            }}
          />
        ) : (
          <div className=''>
            <p className='text-4xl font-semibold tracking-tight lg:text-5xl font-display'>
              Hello there! 👋
            </p>
            <p className='mt-3 opacity-60'>
              Please login to continue accessing our content
            </p>
            <form onSubmit={submit} className='grid grid-cols-12 gap-4 mt-5'>
              <div className='col-span-12 space-y-1.5 text-left'>
                <Label className='text-sm' htmlFor='email'>
                  Email
                </Label>
                <Input
                  ref={emailInputRef}
                  type='email'
                  id='email'
                  required
                  onInput={(evt) => setEmail(evt.target.value)}
                  className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                />
                {loginError && (
                  <div className='flex items-center justify-between'>
                    <ErrorMessage message={loginError} />
                    {loginError === "Please verify your email first!" && (
                      <div
                        className='text-xs text-blue-600 cursor-pointer'
                        onClick={onSendToken}
                      >
                        {emailVerificationLoading ? (
                          <p className='animate-pulse text-slate-500'>
                            Sending verification code...{" "}
                          </p>
                        ) : (
                          "Verify Email"
                        )}
                      </div>
                    )}
                  </div>
                )}
                {startup && !checkEmailDomain(email, startup.website) && (
                  <small className='text-red-500'>
                    This email does not match the startup website!
                  </small>
                )}
              </div>
              <div className='col-span-12 space-y-1.5 text-left'>
                <Label className='text-sm' htmlFor='password'>
                  Password
                </Label>
                <Input
                  type='password'
                  id='password'
                  required
                  onInput={(evt) => setPassword(evt.target.value)}
                  className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                />
              </div>
              <div className='flex items-center justify-end col-span-12'>
                <Link legacyBehavior href={"/forgot-password"} passHref>
                  <a className='inline-flex items-center text-sm font-medium underline duration-300 hover:opacity-70'>
                    Forgot Password
                  </a>
                </Link>
              </div>
              <div className='flex flex-col items-center col-span-12 gap-4'>
                <Button
                  type='submit'
                  variant='secondary'
                  className='w-full'
                  disabled={isLoading}
                >
                  {isLoading && (
                    <CircleNotch
                      weight='bold'
                      className='mr-2 text-lg animate-spin'
                    />
                  )}
                  {isLoading ? "Processing..." : "Login"}
                </Button>
                {/* Hide this if user login from register or claim startup step */}
                {!startup && (
                  <div className='flex flex-col items-center w-full gap-4'>
                    <div className='flex items-center w-full -my-1 space-x-3'>
                      <span className='flex-1 h-px bg-background/10'></span>
                      <span className='text-[10px] uppercase font-medium tracking-wider'>
                        or
                      </span>
                      <span className='flex-1 h-px bg-background/10'></span>
                    </div>
                    <GoogleSignInButton />
                  </div>
                )}
              </div>
            </form>
            <div className='flex justify-center'>
              <button
                type='button'
                onClick={switchPopup}
                className='mt-4 text-xs font-medium underline opacity-60'
              >
                {`I don't have an account`}
              </button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Login;
