import investorBenefits from "@/assets/json/investor-benefits.json";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import availablePricesDev from "@/hooks/availablePrices.dev";
import availablePricesMonthlyDev from "@/hooks/availablePrices.monthly.dev";
import availablePricesMonthlyProd from "@/hooks/availablePrices.monthly.prod";
import {
  ArrowFatLineUp,
  Check,
  X,
  CheckCircle,
  XCircle,
} from "@phosphor-icons/react";
import availablePricesProd from "@/hooks/availablePrices.prod";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";

import {
  hidePurchasePopup,
  registerPopup,
  vcToClaimAtom,
  userAtom,
  purchaseVCPopup,
} from "@/Atoms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAtom } from "jotai";
import CardForm from "../stripe/CardForm";
import useAuth from "@/hooks/auth";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import AppContext from "@/AppContext";
import Image from "next/legacy/image";
import allAccessIcon from "@/assets/images/all_access_icon.png";
import { ScrollArea } from "../ui/scroll-area";
import { getStripePrice } from "@/services/api/Stripe";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import Link from "next/link";
import ErrorMessage from "../ErrorMessage";
import {
  checkVCExist,
  createVCByUser,
  updateVC,
} from "@/services/api/VentureCapital";
import checkEmailDomain, {
  getBaseDomain,
  isValidDomain,
  isValidEmail,
} from "@/utils/checkEmailDomain";
import InputTokenForAccountRegister from "./InputTokenForAccountRegister";
import {
  sendToken,
  verifyTokenForUserRegister,
  verifyTokenNotUser,
} from "@/services/api/Master";
import toast from "react-hot-toast";
import { createAbandonedCart } from "@/services/api/User";
import VCCheckout from "./VCCheckout";
import VCOwnerModal from "./VCOwner";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const PurchaseVC = () => {
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchaseVCPopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [vcToClaim, setVCToClaim] = useAtom(vcToClaimAtom);

  const router = useRouter();
  const { type } = router.query;
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [vcName, setVCName] = useState("");
  const [vcWebsite, setVCWebsite] = useState("");
  const [error, setError] = useState({});
  const [vcExist, setVCExist] = useState(false);
  const [isSameUserEmailAndWebsite, setIsSameUserEmailAndWebsite] =
    useState(false);
  const [foundVC, setFoundVC] = useState(null);
  const [claimedVC, setClaimedVC] = useState(null);
  const [selectedStep, setSelectedStep] = useState(1);
  const { user } = useAuth();
  const [isOpenVCOwner, setIsOpenVCOwner] = useState(false);
  const [selectedVCOwner, setSelectedVCOwner] = useState(null);
  const [officialEmail, setOfficialEmail] = useState("");
  const [confirmedOfficialEmail, setConfirmedOfficialEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] =
    useState(false);
  const [emailConfirmLoading, setEmailConfirmLoading] = useState(false);
  const [confirmToken, setConfirmToken] = useState("");
  const [needToPutOfficialEmail, setNeedToPutOfficialEmail] = useState(false);
  const [userState, setUserState] = useAtom(userAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [sendEmailVerificationLoading, setSendEmailVerificationLoading] =
    useState(false);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const onVerifyTokenUserRegister = async (e) => {
    e.preventDefault();
    if (!confirmToken) toast.error("Token is required!");
    setEmailVerificationLoading(true);
    setEmailConfirmLoading(true);
    try {
      const response = await verifyTokenNotUser({
        token: confirmToken,
      });
      try {
        if (planSubscription.is_vc_plan) {
          if (claimedVC) {
            await handleClaimVC(claimedVC.id);
          } else {
            await handleRegisterVC();
          }
        } else {
          // setSelectedStep(2);
          onUpgradeVC();
        }
        setConfirmedOfficialEmail(true);
        setIsSendingVerifyEmail(false);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      toast.error("Code is invalid or expired.");
    }
    setEmailVerificationLoading(false);
    setEmailConfirmLoading(false);
  };

  const onSendToken = async () => {
    if (!officialEmail) toast.error("Official Email is required!");
    // const freeEmails = ['yahoo.com', 'gmail.com', 'outlook.com', 'aol.com']

    // if (freeEmails.includes(officialEmail.split("@")[1])) {
    //   return toast.error('Email must be an official email!')
    // }

    setEmailVerificationLoading(true);
    try {
      const response = await sendToken({ email: officialEmail });
      toast.success(
        `Verification token just sent to ${officialEmail}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setEmailVerificationLoading(false);
  };

  const onResendToken = async () => {
    if (!officialEmail) toast.error("Official Email is required!");
    // const freeEmails = ['yahoo.com', 'gmail.com', 'outlook.com', 'aol.com']

    // if (freeEmails.includes(officialEmail.split("@")[1])) {
    //   return toast.error('Email must be an official email!')
    // }

    setSendEmailVerificationLoading(true);
    try {
      const response = await sendToken({ email: officialEmail });
      toast.success(
        `Verification token just sent to ${officialEmail}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setSendEmailVerificationLoading(false);
  };

  const handleUserIpAddress = async () => {
    let response;

    try {
      response = await axios.get(
        "https://api.bigdatacloud.net/data/ip-geolocation",
        {
          params: {
            key: "bdc_e7257c8213814ec68c8bf6708ad4431d",
          },
        }
      );
      console.log(response);
    } catch (error) {}

    const availablePrices =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesDev
        : availablePricesProd;

    const availablePricesMonthly =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesMonthlyDev
        : availablePricesMonthlyProd;

    let priceData = {};
    let priceDataMonthly = {};
    let currencyCode = "USD";
    let priceIds = availablePrices["USD"];
    let priceIdsMonthly = availablePricesMonthly["USD"];
    const codes = ["AED", "IDR", "JOD", "EGP", "SAR"];

    if (response && response.data?.country) {
      const currency = response.data.country.currency;
      if (currency && currency.code) {
        if (codes.includes(currency.code)) {
          currencyCode = currency.code;
          priceIds = availablePrices[currencyCode];
          priceIdsMonthly = availablePricesMonthly[currencyCode];
        }
      }
    }

    for (const pKey in priceIds) {
      console.log(priceIds);
      const stripePriceDataResponse = await getStripePrice(priceIds[pKey]);
      const stripePriceData = stripePriceDataResponse.data;

      const stripePriceDataMonthlyResponse = await getStripePrice(
        priceIdsMonthly[pKey]
      );
      const stripePriceDataMonthly = stripePriceDataMonthlyResponse.data;

      priceData[pKey] = stripePriceData;
      priceDataMonthly[pKey] = stripePriceDataMonthly;
    }
    setState({
      userLocation: response?.data || null,
      currencyCode,
      priceData,
      priceDataMonthly,
      priceIds,
      priceIdsMonthly,
      ip: response?.data.ip,
    });
  };

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: userState?.id,
      email: userState?.email,
      action: "Click Register VC Button",
      location: router.pathname,
      label: "VC Registration",
    });
  };

  useEffect(() => {
    handleUserIpAddress();
    if (vcToClaim) {
      setVCName(vcToClaim.name);
      setVCWebsite(vcToClaim.website);
      onClaimVC(vcToClaim);
    }
    handleAbandonedCart();
  }, []);

  const handleCheckVCInfo = async () => {
    setError({
      vcName: !vcName ? "VC name is required!" : "",
      vcWebsite: !vcWebsite ? "VC website is required!" : "",
    });

    if (!vcName || !vcWebsite) return;
    if (!isValidDomain(vcWebsite)) {
      return setError({
        vcName: !vcName ? "VC name is required!" : "",
        vcWebsite:
          "The website is invalid!" +
          (!vcWebsite.includes("http")
            ? " It must have https:// or http:// at the beginning."
            : ""),
      });
    }

    const checkVCResponse = await checkVCExist({
      name: vcName,
      website: getBaseDomain(vcWebsite),
    });
    setVCExist(checkVCResponse.data.exists);
    const same = checkEmailDomain(user.email, vcWebsite);
    setIsSameUserEmailAndWebsite(same);
    if (checkVCResponse.data && checkVCResponse.data.exists) {
      setFoundVC(checkVCResponse.data.vc);
      setSelectedStep(1);
    } else {
      if (same) {
        if (planSubscription.is_vc_plan) {
          handleRegisterVC();
        } else {
          // setSelectedStep(2);
          onUpgradeVC();
        }
        setFoundVC(null);
      } else {
        setNeedToPutOfficialEmail(true);
        // try {
        //   await sendTokenNotUser({ email: '' });
        //   toast.success(`We sent code verification to your email ${email}`, {
        //     duration: 5000,
        //   });
        //   setRegisterCodeVerifyInputOpen(true);
        // } catch (error) {
        // }
      }
    }
  };

  const handleCancel = () => {
    setOpenPurchasePopup(false);
    setError({});
    setVCName("");
    setVCWebsite("");
    setSelectedStep(null);
    setVCExist(false);
    setFoundVC(null);
  };

  const handleReset = () => {
    setError({});
    setVCName("");
    setVCWebsite("");
    setSelectedStep(null);
    setVCExist(false);
    setFoundVC(null);
    setSelectedStep(1);
  };

  const onSelectOwner = (value) => {
    setIsOpenVCOwner(true);
    setSelectedVCOwner(value);
  };

  const handleRegisterVC = async () => {
    const payload = {
      name: vcName,
      website: vcWebsite,
      creator_id: user.id,
      owner_id: user.id,
      owner_email: officialEmail,
      owning_type: "registered",
      investor_type: "venture-capital",
    };
    setIsLoadingSubmit(true);
    try {
      const response = await createVCByUser(payload);
      toast.success("Your VC has been successfully created");
      location.href = `/investors/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(true);
  };

  const onClaimVC = (vc) => {
    const same = checkEmailDomain(user.email, vc.website);
    setIsSameUserEmailAndWebsite(same);
    if (same) {
      if (planSubscription.is_vc_plan) {
        handleClaimVC(vc.id);
      } else {
        setClaimedVC(vc);
        // setSelectedStep(2);
        onUpgradeVC();
      }
      setFoundVC(null);
    } else {
      setClaimedVC(vc);
      setNeedToPutOfficialEmail(true);
    }
  };

  const handleClaimVC = async (vcId) => {
    const payload = {
      name: vcName,
      website: vcWebsite,
      owner_id: user.id,
      claimed: 1,
      owner_email: officialEmail,
    };

    setIsLoadingSubmit(true);
    try {
      const response = await updateVC(vcId, payload);
      setVCToClaim(null);
      setClaimedVC(null);
      toast.success("Your VC has been successfully updated");
      location.href = `/investors/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(true);
  };

  const onUpgradeVC = (type) => {
    // if (!user) {
    //   return setOpenLoginPopup(true);
    // }
    setIsOpen(true);
  };

  return (
    <Dialog open={!!openPurchasePopup} onOpenChange={setOpenPurchasePopup}>
      <DialogContent
        isHidePurchasePopup={isHidePurchasePopup}
        className={`max-w-6xl p-0 bg-white text-background ${
          isHidePurchasePopup ? "hidden" : ""
        }`}
        hideCloseButton
      >
        <ScrollArea className='max-h-[100dvh]'>
          <div className='flex flex-col lg:flex-row'>
            <div className='relative lg:flex-1 lg:m-2.5 lg:mr-0 overflow-hidden bg-gray-100 lg:rounded-md shadow-inner'>
              <button
                onClick={() => setOpenPurchasePopup(false)}
                type='button'
                className='absolute inline-flex md:hidden items-center justify-center rounded-full w-9 h-9 bg-white/[0.15] right-3 top-3'
              >
                <X className='text-xl text-white' />
              </button>
              <div className='p-8 pt-5 text-center text-white lg:pb-10 bg-gradient-to-tr from-orange-1 to-red-1'>
                <span className='inline-flex items-center justify-center mb-3 text-3xl rounded-full w-14 h-14 bg-white/20'>
                  <ArrowFatLineUp />
                </span>
                <p className='text-3xl font-semibold tracking-tight lg:text-5xl font-display'>
                  Upgrade to the VC Registration Plan!
                </p>
                <p className='mt-3 opacity-80'>
                  To edit and publish your vc profile, you&apos;ll need to
                  purchase our VC Registration Plan for{" "}
                  <span className='font-semibold'>
                    {state?.currencyCode}{" "}
                    {(
                      state?.priceData["VC Registration"].unit_amount / 100
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    /year
                  </span>
                </p>
              </div>
              <div className='justify-center hidden lg:flex lg:-mt-5'>
                <div className='flex items-center p-1 pr-4 bg-white rounded-full shadow-lg gap-x-2'>
                  <div className='overflow-hidden border rounded-full w-9 h-9 border-background/20'>
                    <div className='relative w-9 h-9 aspect-square'>
                      <Image
                        src={allAccessIcon.src}
                        alt=''
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                  </div>
                  <div>
                    <p className='text-xs font-medium leading-none'>
                      VC Registration
                    </p>
                  </div>
                </div>
              </div>
              <div className='grid gap-2 p-5 text-sm lg:grid-cols-2 lg:text-xs lg:p-8 text-background'>
                {investorBenefits.map((item, index) => (
                  <div
                    className='inline-flex items-start space-x-2.5'
                    key={index}
                  >
                    <span className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                      <Check weight='bold' className='text-xs' />
                    </span>
                    <span className='translate-y-px'>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='p-5 lg:p-7 lg:w-2/5'>
              {/* VC Information */}
              {selectedStep === 1 && !needToPutOfficialEmail && (
                <div>
                  <div className='grid grid-cols-12 gap-4 mt-10'>
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>VC Name</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        required
                        value={vcName}
                        onInput={(e) => setVCName(e.target.value)}
                        disabled={foundVC}
                      />
                      {error && error.vcName && (
                        <ErrorMessage message={error.vcName} />
                      )}
                      {/* <div className='inline-flex items-start space-x-1.5 text-xs text-green-500'>
                      <Check weight='bold' className='text-sm' />
                      <span className='font-medium'>
                        You can proceed with this name
                      </span>
                    </div> */}
                    </div>
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>VC Website</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        required
                        value={vcWebsite}
                        onInput={(e) => setVCWebsite(e.target.value)}
                        disabled={foundVC}
                      />
                      {error && error.vcWebsite && (
                        <ErrorMessage message={error.vcWebsite} />
                      )}
                      {vcExist && !foundVC?.owner_id && (
                        <div className='inline-flex items-start space-x-1.5 text-xs text-red-600'>
                          <span className='font-medium'>VC already exists</span>
                        </div>
                      )}
                      {vcExist && foundVC?.owner_id && (
                        <div className='inline-flex items-start space-x-1.5 text-xs text-red-600'>
                          <X weight='bold' className='text-sm' />
                          <span className='font-medium'>
                            VC already exists and has been claimed.
                          </span>
                        </div>
                      )}
                    </div>
                    {selectedStep == 1 && foundVC && (
                      <div className='col-span-12 mt-4'>
                        <p className='font-medium'>
                          We found the VC, is it yours?
                        </p>

                        <div className='divide-y divide-black/10 max-h-[30vh] 2xl:max-h-[40vh] overflow-y-auto -my-2 pt-4'>
                          <div className='gap-4 py-2'>
                            <div className='flex items-start space-x-3'>
                              <div className='relative w-10 overflow-hidden border rounded-lg shadow-sm border-background/10 aspect-square'>
                                <Image
                                  src={foundVC.logo_url || DummyLogo}
                                  layout={"fill"}
                                  objectFit={"cover"}
                                  alt='Image'
                                />
                              </div>
                              <div>
                                <div className='relative text-sm font-medium text-background'>
                                  <span className='mr-6'>{foundVC.name}</span>
                                  {foundVC.owner_id && (
                                    <span className='w-full px-2 py-1 text-xs font-semibold text-center uppercase border rounded bg-gradient-to-r from-yellow-500/20 to-orange-500/20 border-white/30'>
                                      Claimed
                                    </span>
                                  )}
                                </div>
                                <a
                                  href={foundVC.website}
                                  className='block mt-0.5 text-xs font-medium hover:underline opacity-50'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {foundVC.website}
                                </a>
                                <div className='flex mt-2 gap-x-4'>
                                  {foundVC.is_published && (
                                    <a
                                      target='_blank'
                                      href={`/investors/${foundVC.slug}-${foundVC.id}`}
                                      rel='noopener noreferrer'
                                      className='px-2 py-1 text-xs font-medium bg-gray-100 border border-gray-200 rounded-md'
                                    >
                                      Review
                                    </a>
                                  )}
                                  {!foundVC.owner_id && (
                                    <Button
                                      className='text-xs bg-transparent text-background'
                                      size='xs'
                                      onClick={() => onClaimVC(foundVC)}
                                      disabled={isLoadingSubmit}
                                    >
                                      Claim
                                    </Button>
                                  )}
                                  {foundVC.owner_id &&
                                    checkEmailDomain(
                                      user?.email,
                                      foundVC.website
                                    ) && (
                                      <Button
                                        className='text-xs bg-transparent text-background'
                                        size='xs'
                                        onClick={() => onSelectOwner(foundVC)}
                                      >
                                        Who Claimed This?
                                      </Button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedStep == 1 && !vcExist ? (
                    <div className='col-span-12'>
                      <div className='flex gap-x-1.5 mt-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='secondary'
                          className='flex-1'
                          onClick={handleCheckVCInfo}
                          disabled={isLoadingSubmit}
                        >
                          Register VC
                        </Button>
                      </div>
                      <p className='mt-3 text-xs opacity-50'>
                        We need to check whether or not your vc already exists
                        on Lucidity Insights&apos; platform. If it does, you
                        have the option to immediately claim your vc.
                      </p>
                    </div>
                  ) : (
                    vcExist && (
                      <div className='flex justify-end mt-4 gap-x-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  )}
                </div>
              )}

              {selectedStep === 1 &&
                needToPutOfficialEmail &&
                !isSendingVerifyEmail && (
                  <div className='flex-1 space-y-1.5'>
                    <div className='mt-10'>
                      <h2 className='mb-2 text-sm'>
                        Please enter the official email address for{" "}
                        <span className='font-semibold'>{vcWebsite}</span> as
                        your email address does not match it.
                      </h2>
                      <div className='flex items-end col-span-12 space-x-4'>
                        <div className='flex-1 space-y-1.5'>
                          <Label className='text-xs' htmlFor='official_email'>
                            Official Email{" "}
                            <span className='text-red-700'>*</span>
                          </Label>
                          <Input
                            type='text'
                            id='official_email'
                            className='bg-white ring-offset-white border-background/20'
                            value={officialEmail}
                            name='official_email'
                            onChange={(e) => setOfficialEmail(e.target.value)}
                            placeholder={`xxxxxx@${getBaseDomain(vcWebsite)}`}
                            disabled={confirmedOfficialEmail}
                            autoComplete='off'
                          />
                        </div>
                        <div>
                          {!confirmedOfficialEmail ? (
                            <Button
                              variant='secondary'
                              onClick={onSendToken}
                              disabled={
                                !officialEmail ||
                                emailVerificationLoading ||
                                !isValidEmail(officialEmail)
                              }
                            >
                              {emailVerificationLoading
                                ? "Processing..."
                                : "Verify Email"}
                            </Button>
                          ) : (
                            <div className='inline-flex items-center h-12 px-4 space-x-2 text-sm font-medium text-white bg-green-600 rounded-md'>
                              <p>Email is verified</p>
                              <CheckCircle weight='fill' />
                            </div>
                          )}
                        </div>
                      </div>
                      <p className='col-span-12 mt-2 text-xs text-background/60'>
                        To automate the process we need your official email
                        matched with your official website, if not we will
                        verify manually
                      </p>
                    </div>
                  </div>
                )}

              {selectedStep === 1 &&
                needToPutOfficialEmail &&
                isSendingVerifyEmail && (
                  <div className='mt-10'>
                    <InputTokenForAccountRegister
                      {...{
                        isVisible: isSendingVerifyEmail
                          ? true
                          : openRegisterPopup,
                        onVerifyTokenUserRegister,
                        emailVerificationLoading,
                        confirmToken,
                        setConfirmToken,
                        isLoading,
                        sendEmailVerificationLoading,
                        onSendTokenForAccountRegistration: onResendToken,
                      }}
                    />
                  </div>
                )}

              {selectedStep == 2 && (
                <div className='w-full p-3 my-6 rounded bg-slate-200'>
                  {claimedVC && (
                    <p className='mb-3 text-sm font-semibold'>
                      You are about to claim this vc
                    </p>
                  )}
                  <div className='flex justify-between gap-x-4'>
                    <div className='w-1/2'>
                      <p className='text-xs'>VC Name</p>
                      <p className='text-sm'>
                        {claimedVC ? claimedVC.name : vcName}
                      </p>
                    </div>
                    <div className='w-1/2'>
                      <p className='text-xs'>VC Website</p>
                      <p className='text-sm'>
                        {claimedVC ? claimedVC.website : vcWebsite}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {state && selectedStep == 2 ? (
                <Elements stripe={stripePromise} options={options}>
                  <CardForm
                    theme='light'
                    planType={"VC Registration"}
                    refreshUserSubscription={refreshUserSubscription}
                    planSubscription={planSubscription}
                    {...{
                      userLocation: state?.userLocation,
                      priceData: state?.priceData,
                      priceDataMonthly: state?.priceDataMonthly,
                      priceIds: state?.priceIds,
                      priceIdsMonthly: state?.priceIdsMonthly,
                      currencyCode: state?.currencyCode,
                      ip: state?.ip,
                    }}
                    nextAction={
                      claimedVC
                        ? () => handleClaimVC(claimedVC.id)
                        : () => handleRegisterVC()
                    }
                  />
                </Elements>
              ) : (
                !state &&
                selectedStep == 2 && (
                  <div className='flex items-center justify-center'>
                    <p className='text-slate-500 animate-pulse'>
                      Loading Payment
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
      {isOpenVCOwner && (
        <VCOwnerModal
          isOpen={isOpenVCOwner}
          setIsOpen={setIsOpenVCOwner}
          vc={selectedVCOwner}
        />
      )}
      <VCCheckout
        {...{
          userLocation: state?.userLocation,
          priceData: state?.priceData,
          priceDataMonthly: state?.priceDataMonthly,
          priceIds: state?.priceIds,
          priceIdsMonthly: state?.priceIdsMonthly,
          currencyCode: state?.currencyCode,
          ip: state?.ip,
          isOpen,
          setIsOpen,
          createVC: true,
          createVCPayload: {
            name: vcName,
            website: vcWebsite,
            proposed_by: user.id,
            proposed_owner_email: user.email,
            owning_type: "registered",
            investor_type: "venture-capital",
          },
        }}
      />
    </Dialog>
  );
};

export default PurchaseVC;
